@use "assets/variables" as *;
@use "node_modules/ngx-smart-modal/ngx-smart-modal" as *;

* {
  box-sizing: border-box;
}
html, body {
  background-color: var(--form-nav-background-color);
  font-style: var(--form-fontstyle);
  color: var(--global-text-color);
}

app-root, webbooker-widget, inline-webbooker, popup-widget, .flatpickr-calendar {
  font-family: var(--form-fontstyle);
  font-size: $global-font-size;
  line-height: 20px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  html {
    font-family: var(--form-fontstyle);
    font-size: $global-font-size;
    line-height: 20px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    color: $global-text-color;
  }

  img {
    height: auto; /* Make sure images are scaled correctly. */
    max-width: 100%; /* Adhere to container width. */
  }

  h1, h2, h3, h4, h5 {
    color: $global-title-color !important;
  }

  h1 {
    font-size: 26px !important;
    line-height: 30px !important;
  }

  h1, h2, h3, h4, h5, p {
    margin-top: 0;
  }

  a, .productSelectionModal a {
    color: $global-link-color;
    -webkit-text-fill-color: initial;
  }
  label.description {
    a {
     color: $global-link-color !important;
    }
  }
  .hidden {
    display: none;
  }
  .wb-mat-table {
    width: 100%;
    display: flow-root;
    th {

    }
  }

  input[type=checkbox] {
    cursor: pointer;
  }

  ng2-flatpickr {
    input[type=text] {
      height: 45px;
      line-height: 45px;
      border: 0;
      width: 100%;
      background: #FFFFFF;
      padding: 0 10px;
      transition: all 0.3s ease-in-out;
      box-shadow: inset 0 0 0 1px $input-border-color;

      &.active,
      &:focus {
        outline: none;
        box-shadow: inset 0 0 0 2px $input-focus-border-color;
        transition: all 0.3s ease-in-out;
        border: 0px !important;
      }
    }
  }

  .dateTime.error {
    input[type=date],
    input[type=time] {
      box-shadow: inset 0 0 0 2px $global-error-color;
    }

    .wb-select {
      background: $input-background-color !important;

      select {
        border-top: 2px solid $global-error-color !important;
        border-left: 2px solid $global-error-color !important;
        border-bottom: 2px solid $global-error-color !important;
        color: $input-text-color !important;
      }

      svg {
        g g {
          fill: $input-text-color !important;
        }
      }
    }
  }

  .iti {
    width: 100%;
    color: $input-text-color;

    *:focus {
      outline: none;
    }

    .iti__selected-flag {
      padding: 0 10px;
      height: 41px;
      margin-top: 1px;
      margin-left: 1px;
    }

    .iti__country {
      color: #000;
    }
  }

  .iti--separate-dial-code .iti__selected-flag {
    background: #EFEEEE;
  }

  .ng-select,
  .ng-select.ng-select-single {
    .ng-select-container {
      border-color: $input-border-color;
      height: 45px;
      line-height: 45px;

      .ng-value-container {
        .ng-input {
          top: 0;
        }
      }
    }
  }

  ng-autocomplete {
    .autocomplete-container {
      box-shadow: none;
      height: 45px;

      .input-container {
        input {
          box-shadow: inset 0 0 0 1px $input-border-color;
          height: 45px;
          line-height: 45px;
          transition: box-shadow 0.3s ease-in-out;
          padding: 0 35px 0 10px;
          color: $global-text-color;
          appearance: none;

          &:focus {
            box-shadow: inset 0 0 0 2px $input-focus-border-color;
          }
        }
      }

      .suggestions-container {
        border: 0;
      }

      &.active {
        .suggestions-container.is-visible {
          text-align: left;
          border: 1px solid $input-border-color;
          border-top: 0;
          padding-bottom: 30px;
          background: #FFF url('assets/powered-by-google/powered_by_google_on_white.png') no-repeat right 10px bottom 5px;
        }

        .not-found {
          display: none;
        }
      }

      .not-found {
        position: relative;
        z-index: 10;
        //margin-top: -1px;
        border: 1px solid $input-border-color;
        border-top: 0;
      }

      .suggestions-container + .suggestions-container {
        display: none;
      }
    }
  }

  .ng-autocomplete {
    &.error {
      ng-autocomplete .autocomplete-container .input-container input {
        box-shadow: inset 0 0 0 2px $global-error-color;
      }
    }
  }

  nav.wb-form, nav.wb-widget-form {
    padding: 10px;
    display: block;
    position: fixed;
    border-top: 1px solid var(--form-nav-border-color);
    background: var(--form-nav-background-color);
    bottom: 0;
    width: 100%;
    z-index: 100;
    text-align: center;
    container-type: inline-size;
    //@media (min-width: 900px) {
    //  padding: 20px 0 0 0;
    //}
    .wb-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: initial;
      box-sizing: border-box;
      container-type: inline-size;
      max-width: 860px;
      margin: 0 auto;

      //padding: 0 20px;
      @container (min-width: 940px) {
        width: 860px;
        margin: 0 auto;
      }
    }

    .wb-formstyle-inline {
      padding: 10px;
    }

    .wb-error-list {
      color: #FFF;
      background: transparent;
      opacity: 0;
      padding: 0 8px;
      border-radius: 16px;
      transition: background 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out;
      text-align: left;
      font-weight: bold;
      display: none;

      &.wb-active {
        padding: 3px 8px;
        opacity: 1;
        background: $global-error-color;
        margin-bottom: 10px;
        @media(min-width: 900px) {
          display: inline-block;
        }
      }

      span {
        font-size: 14px;
        line-height: 20px;
        display: block;
      }
    }

    button,
    a {
      flex: $form-nav-button-flex;
      display: block;
      background: $form-nav-button-background-color;
      color: $form-nav-button-text-color;
      max-width: $form-nav-button-width;
      width: 100%;
      appearance: none;
      border: 0;
      margin: auto;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      padding: 0 20px;
      text-decoration: none;
      text-align: center;

      &.wb-poweredBy {
        color: $input-text-color;
        margin: 10px auto;
      }

      &.wb-squared {
        border-radius: 0px;
      }

      @container (max-width: 900px) {
        width: 100%;
      }

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }

      &.wb-previous {
        border: 1px solid $form-nav-back-button-border-color;
        color: $form-nav-back-button-text-color;
        background: $form-nav-back-button-background-color;
      }

      &:disabled {
        opacity: 0.5;
      }

      & + a,
      & + button {
        @container (min-width: 900px) {
          margin-left: 20px;
        }

        &:focus {
          outline: none;
        }
      }
    }

    button {
      filter: brightness(1);
      moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;

      &:hover {
        filter: brightness(1.3);
        moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
      }
    }

    .wb-previous {
      width:100%;
      margin: 0 auto;
      @media (width > 620px) {
        width: 50%;
      }
      &:hover {
        filter: brightness(1);
        moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
      }
    }

    .wb-next {
      width:auto;
      flex:1;
      margin-left:20px;
      //@container (max-width: 900px) {
      //  width: calc(100% - 125px);
      //  margin-left: 20px;
      //}
      //
      //@container (max-width: 700px) {
      //  width: calc(100% - 165px);
      //  margin-left: 20px;
      //}

      &:hover {
        cursor: pointer;
        background: $form-nav-button-background-colorA;
      }
    }

    //& .wb-button-small {
    //  @container (min-width: 900px) {
    //    max-width:400px;
    //  }
    //}
  }

  nav.wb-widget-form {
    background-color: var(--form-nav-background-color);
    border-top: 0px;
  }

  nav.wb-page-0 {
    .wb-next {
       width: 100% !important;
       margin-left: 0px !important;
    }
  }

  nav.wb-page-2 {
    @container (max-width: 600px) {
      .wb-previous {
        width:auto;
        min-width:100px;
        //display:flex;
        //&:first-of-type {
        //  margin-right:15px;
        //}
      }
    }

  }

  nav.wb-page-1 {
    .retour-nav {
      .wb-previous {
        width:auto;
        min-width:100px;
      }
    }
  }


  nav.wb-widget-form {
    width: 100%;
    position: relative;
    padding: 10px 0;
    margin: 20px;
    background-color: var(--form-nav-background-color);

    @container (min-width: 768px) {
      padding: 20px;
      margin: 0 0 0 0;
    }
    @container (max-width: 768px) {
      width: calc(100% - 40px);
    }

    .wb-container {
      @container (min-width: 768px) {
        width: 100%;
        margin: 0 auto;
      }
    }

    .wb-next {
      max-width: none;
      @media (max-width: 960px) {
        width: 100%;
      }

      &:hover {
        cursor: pointer;
        background: $form-nav-button-background-colorA;
      }
    }
  }

  nav.wb-widget-form {
    &.wb-page-0, &.wb-page-1, &.wb-page-2, &.wb-page-3 {
      width: 100%;
      margin: 0;
    }
  }

  .wb-widget-form.wb-page-1, .wb-widget-form.wb-page-2, .wb-widget-form.wb-page-3 {
    padding: 20px 0 0 0;
  }

  nav.wb-page-1 {
    button.wb-previous {
      @media (max-width: 900px) {
        //width: 25%;
        //float: left;
        margin: 0 auto;
      }
    }
  }

  .wb-widget-form.wb-page-1 {
    padding: 20px 0 0 0;
  }

  nav.wb-page-1, nav.wb-page-2, nav.wb-page-3, nav.wb-page-4 {
    button.next {
      @container (max-width: 700px) {
        margin-left: 10px;
        margin-top: 0;
        width: calc(75% - 10px);
      }
    }
  }

  input,
  textarea {
    appearance: none;
    border-radius: 0;
    font-size: 16px !important;
    color: $input-text-color;
  }

  select {
    color: $input-text-color;
  }

  input[type=text],
  input[type=password],
  input[type=date],
  input[type=time],
  input[type=datetime-local],
  input[type=search],
  input[type=email],
  input[type=phone] {
    width: 100%;
    box-shadow: inset 0 0 0 1px $input-border-color;
    transition: box-shadow 0.3s ease-in-out;
    background: $input-background-color;
    border: 0;
    height: 45px;
    line-height: 45px;
    padding: 0 10px;

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 2px $input-focus-border-color;
    }

    &.error {
      box-shadow: inset 0 0 0 2px $global-error-color;
    }
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $input-placeholder-color;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $input-placeholder-color;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $input-placeholder-color;
  }

  ngx-smart-modal {
    .overlay {
      position: fixed;
    }

    .nsm-dialog-btn-close {
      display: none;
    }

    .nsm-content {
      @media (min-width: 960px) {
        padding: 25px;
      }
      @media (max-width: 960px) {
        margin: 0px;
      }
    }

    .nsm-body {
      h3 {
        font-size: 17px;
        line-height: 21px;
        font-weight: normal;

        .close {
          display: inline-block;
          float: right;

          &:hover {
            cursor: pointer;
          }
        }
      }

      p {
        font-size: 15px;
        line-height: 19px;
        margin: 0;
        color: $global-text-color;
      }
    }

    .termsAndConditionsModal {
      .text-container {
        padding:10px 0;
      }
      .nsm-dialog-btn-close{
        display: none;
      }
    }

    .navigation-error {
      padding-top:0 !important;
    }

    .payment-preauth-model {
      button,
      a {
        flex: $form-nav-button-flex;
        display: block;
        background: $form-nav-button-background-color;
        color: $form-nav-button-text-color;
        max-width: $form-nav-button-width;
        //width: 100%;
        appearance: none;
        border: 0;
        margin: auto;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        padding: 0 20px;
        text-decoration: none;
        text-align: center;
        cursor: pointer;

        &.nsm-dialog-btn-close {
          width: auto;
          padding: 0 10px;
          height: auto;
          line-height: 30px;
          svg {
            width:10px;
            height:10px;
          }
        }

        &.wb-button-small {
          width:auto;
          float:right;
          margin-left:10px;
        }

        filter: brightness(1);
        moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;

        &:hover {
          filter: brightness(1.3);
          moz-transition: all .3s ease-in-out;
          -o-transition: all .3s ease-in-out;
          -webkit-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
        }
      }
    }

    @media(width < 500px) {
      .nsm-dialog {
        margin: 20px 0;
        width:100%;
      }
    }

    .nsm-content {
      padding: 20px 20px 40px 20px;
      background-color: var(--form-nav-background-color);
    }

    .nsm-dialog {
      .nsm-content {
        background-color: #ffffff;
      }
    }

    .nsm-dialog-animation-ttb.nsm-dialog-open {
      transform: translate3d(0, 0, 0);
      margin-top: min(50px, 20%);
    }
  }

  .service-area-modal {
    .nsm-content {
      width: 100%;
    }
    .nsm-dialog-animation-ttb.nsm-dialog-open {
      transform: translate3d(0, 20%, 0);
      margin-top:0%;
    }
  }

  app-summary {
    .wb-desktop {
      //app-field-value {
      //  display: block;
      //  @media (min-width: 1024px) {
      //    > span {
      //      @media (min-width: 1024px) {
      //        width: 50%;
      //        float: left;
      //        &:first-of-type {
      //          padding-left: 0;
      //        }
      //      }
      //    }
      //  }
      //}
      app-field-wrapper {
        > label {
          > span {
            margin: 0 0 5px !important;
          }
        }
      }
    }

    .wb-fixed-fields {
      .wb-field {
        &.checkbox {
          > label {
            margin: 0 0 12px;

            > span {
              display: none;
            }
          }

          app-checkbox-field {
            font-size: 14px;
            line-height: 16px;

            span {
              position: relative;
            }
          }
        }
      }
    }
  }

  .wb-formtitle {
    display: $form-title-display;
  }

  .td-data-table-cell-content-wrapper, .td-data-table-column span.td-data-table-heading {
    padding: 0px 14px !important;
  }

  .wb-form-slider {
    .wb-form-part {
      .wb-tab-pane {
        margin-bottom: 10px;
        float: left;

        .wb-form_tab {
          float: left;
          border: 0 solid #000000;
          border-bottom: 0;
          border-radius: 10px 10px 0px 0;
          padding: 15px;
          text-align: center;
          width: calc(50% - 23px);
          cursor: pointer;
          background-color: #e3e3e3;

          &:first-child {
            border-right: 0;
            margin-left: 20px;
            margin-right: 3px;
          }

          &:nth-child(2) {
            margin-left: 3px;
          }

          &.active {
            background-color: $global-title-color;
            color: #ffffff;
          }
        }
      }

      .wb-fields {
        @container(min-width: 768px) {
          width: 100%;
        }
        @container(min-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}

#google-error {
  position: fixed;
  bottom: 0px;
  text-align: center;
  width: 100%;
  background-color: rgba(246, 244, 227, 0.94);
  z-index: 2222;
  padding: 10px;
  font-size: 12px;
  font-family: 'Roboto';
  border-top:1px solid #bebebe;
  display:none;
}

.desktop-timePicker .flatpickr-input{
  border: 1px solid var(--global-border-color) !important;
  border-left: 0px !important;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  font-family: var(--form-fontstyle);
  font-size: var(--global-font-size);
  background-color: #ffffff;
  border:2px solid #bebebe;
  padding: 0px;
  border-radius: 10px;

  .mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background-color: #f3f3f3;
  }
}
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0px !important;
}
.confirm-button {
  font-family: var(--form-fontstyle);
  background-color: $global-title-color !important;
}
.confirm-container,.confirm-container-html {
  font-family: var(--form-fontstyle) !important;
  font-size:18px !important;
  line-height: 24px;
}
.confirm-container-html {
  font-size:16px;
}
input.ng-invalid {
  border:1px solid red !important;
}
.lock-screen {
  //height: 100%;
  overflow: hidden;
  //width: 100%;
}

.separator{
  padding:0px 15px;
}
