header {
  font-weight: 700;
}
.productSelectionModal {
  transform: translate3d(0, 0, 0) !important;
  max-width: 1000px;
  width:90%;
  margin:20px auto;
}
ngx-smart-modal.form {
  position: relative;
  display: contents;

  .wb-container {
    width: calc(100% - 40px);

    button {
      width: 48%;
    }
  }
}
.autocomplete-container .suggestions-container.is-visible {
  visibility: hidden !important;
}
.autocomplete-container.active .suggestions-container.is-visible {
  visibility: visible !important;
}

.country-select .flag.zw {
  height: 10px;
  background-position: -5610px 0px
}

.country-select {
  /*color: $ global-text-color;*/
  width: 10px;
}
.country-select.inside .selected-flag {
  width: 50px;
}
.country-select.inside input, .country-select.inside input {
  padding-right:0px;
  background-color: var(--header-background-color);
  border-color:var(--header-background-color);
  box-shadow: none;
}
.country-name{
  display: none;
}
.support-report-modal{
  width:95%;
  max-width: 800px;
  display:block;
  font-size:12px;

  .nsm-dialog {
    max-width: 800px !important;
    .support-report-modal-title {
      padding-bottom:10px;
    }

    .address-input {
      display: block;
    }

    .support-title {
      display:none;
    }

    table {
      width: 100%;
      tr,td {
        text-align:left;
      }
    }
    table,td,{
      padding:0;
      td:first-child {
        font-weight:bold;
      }
    }
    h3 {
      margin-bottom:0;
    }
    ul {
      padding-left:20px;
      font-size:12px;
    }
    table {
      padding-bottom:10px;
      font-size:12px;
    }
  }
}
